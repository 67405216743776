export function calculateAverage(a, b, c) {
  if (typeof a !== 'number' || Number.isNaN(a)) {
    return NaN;
  }

  if (typeof b !== 'number' || Number.isNaN(b)) {
    return a;
  }

  if (typeof c !== 'number' || Number.isNaN(c)) {
    return (a + b) / 2;
  }

  return (a + b + c) / 3;
}

export function notUsed() {
  return true;
}
