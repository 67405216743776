/* eslint-disable */ 
/*
Dependencies:
- jQuery
- socket.udp.js
- jquery.number.js
*/
(function (root) {
  // Set-up the NameSpace
  root.LaunchMonitor = new (function (tpmid, udpPort) {
    const model = function (tpmid, udpPort) {
      const me = this;
      me.udpPort = 41172;
      me.requestedTPMID = 0;

      // Default Parameters. TPMs/CBRMs communicate via
      if (tpmid) {
        me.requestedTPMID = tpmid;
      }
      if (udpPort) {
        me.udpPort = 41172;
      }

      // Buttons that should be added to any UI that implements this
      // me.Buttons = {
      //   Connect: <button alt="Disconnect">Connect</button>,
      //   Disconnect: <button>Disconnect</button>,
      //   Clear: <button>Clear</button>,
      //   DownloadJSON: <button>Download JSON</button>,
      //   Download: <button>Download</button>,
      // };

      // UI form fields
      // me.Inputs = {
      //   Port: <input type="text" value={udpPort} />').val(me.udpPort),
      //   TPMID: <input type="text" value={tpmid} />').val(tpmid),
      // };

      // Visualization panel
      me.VizPanel = <div></div>;

      me.SetTPMID = function (tpmID) {
        me.requestedTPMID = Number(tpmID);
        me.Callbacks.TPMIDChanged(me.requestedTPMID);
      };

      me.Helpers = {
        GUID() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return (
            `${s4()
            + s4()
            }-${
              s4()
            }-${
              s4()
            }-${
              s4()
            }-${
              s4()
            }${s4()
            }${s4()}`
          );
        },
      };

      // Methods that return UI controls. UI should call these methods to add buttons and inputs.
      // me.UI = {
      //   Button: {
      //     Connect(conText, disText, attr) {
      //       return me.UI.ReturnControl('Connect', conText, disText, attr);
      //     },
      //     Disconnect(btnName, attr) {
      //       return me.UI.ReturnControl('Disconnect', btnName, null, attr);
      //     },
      //     Clear(btnName, attr) {
      //       return me.UI.ReturnControl('Clear', btnName, null, attr);
      //     },
      //     DownloadJSON(btnName, attr) {
      //       return me.UI.ReturnControl('DownloadJSON', btnName, null, attr);
      //     },
      //     Download(btnName, attr) {
      //       return me.UI.ReturnControl('Download', btnName, null, attr);
      //     },
      //   },
      //   Input: {
      //     Port(attr) {
      //       return me.UI.ReturnControl('Port', null, null, attr);
      //     },
      //     TPMID(attr) {
      //       return me.UI.ReturnControl('TPMID', null, null, attr);
      //     },
      //   },

        // GetButton(ctlID) {
        //   switch (ctlID) {
        //   case 'Connect':
        //     return me.Buttons.Connect;
        //   case 'Disconnect':
        //     return me.Buttons.Disconnect;
        //   case 'Clear':
        //     return me.Buttons.Clear;
        //   case 'DownloadJSON':
        //     return me.Buttons.DownloadJSON;
        //   case 'Download':
        //     return me.Buttons.Download;
        //   case 'Port':
        //     return me.Inputs.Port;
        //   case 'TPMID':
        //     return me.Inputs.TPMID;
        //   }
        //   return null;
        // },

        // generic method that return a specified control
      //   ReturnControl(ctlID, btnName, btnAltName, attr) {
      //     const ctlRef = me.UI.GetButton(ctlID);

      //     if (btnName) {
      //       ctlRef.html(btnName);
      //     }

      //     // Add any specified attributes
      //     if (attr) {
      //       for (const key in attr) {
      //         ctlRef.attr(key, attr[key]);
      //       }
      //     }

      //     // if btnName2 is specified then add it as a data-attribute
      //     if (btnAltName) {
      //       ctlRef.data('altText', btnAltName);
      //     }
      //     if (ctlRef.data('altText')) {
      //       ctlRef.data('text', ctlRef.html());
      //     }

      //     return ctlRef;
      //   },
      // };

      // Shots Array
      me.shots = [];

      // Initialize
      me.init = function (callbacks) {
        // Set the callbacks if they are defined
        if (callbacks) {
          if (callbacks.Connect) {
            me.Callbacks.Connect = callbacks.Connect;
          }
          if (callbacks.Disconnect) {
            me.Callbacks.Disconnect = callbacks.Disconnect;
          }
          if (callbacks.DataReceived) {
            console.log('data received callback');

            me.Callbacks.DataReceived = callbacks.DataReceived;

            console.log(me.Callbacks.DataReceived);
          }
          if (callbacks.DisplayShot) {
            me.Callbacks.DisplayShot = callbacks.DisplayShot;
          }
          if (callbacks.ErrorReceived) {
            me.Callbacks.ErrorReceived = callbacks.ErrorReceived;
          }
          if (callbacks.TPMIDChanged) {
            me.Callbacks.TPMIDChanged = callbacks.TPMIDChanged;
          }
          if (callbacks.LoadShots) {
            me.Callbacks.LoadShots = callbacks.LoadShots;
          }
          if (callbacks.ClearShots) {
            me.Callbacks.ClearShots = callbacks.ClearShots;
          }
          if (callbacks.DownloadJSON) {
            me.Callbacks.DownloadJSON = callbacks.DownloadJSON;
          }
          if (callbacks.Download) {
            me.Callbacks.Download = callbacks.Download;
          }
        }

        // Bind the Connect button click event
        // me.Buttons.Connect.click(() => {
        //   if (me.udpListener && me.udpListener.connected == true) {
        //     // we are connected. disconnect.
        //     me.udpListener.cleanup(() => {
        //       // me.Buttons.Connect.html(me.Buttons.Connect.data('text'));
        //       me.Callbacks.Disconnect();
        //     });
        //   } else {
        //     // we are not connected, connect.
        //     me.udpListener.cleanup_create(
        //       me.Inputs.Port.val(),
        //       (rc) => {
        //         // data received
        //         const dataView = new DataView(rc.data);
        //         const decoder = new TextDecoder('utf-8');
        //         const decodedString = decoder.decode(dataView);
        //         console.log(decodedString);
        //         jShot = me.processShot(decodedString);

        //         const addShotToArray = jShot.TPMID == me.requestedTPMID;

        //         // data received callback (this callback is where weather is retrieved: in SensorMonitor.js)
        //         me.Callbacks.DataReceived(jShot, addShotToArray);

        //         if (jShot.TPMID == me.requestedTPMID) {
        //           // add shot to local array and update storage
        //           me.AddShot(jShot);
        //         }
        //         // safety valve. If we receive a shot we're connected. Display 'Disconnect' for the button
        //         me.Buttons.Connect.html(me.Buttons.Connect.data('altText'));
        //       },
        //       (info) => {
        //         // data error
        //         me.Callbacks.ErrorReceived(jShot);
        //       },
        //     );
        //     me.Buttons.Connect.html(me.Buttons.Connect.data('altText'));

        //     // call the connect callback
        //     me.Callbacks.Connect(me.requestedTPMID);
        //   }
        // });

        // Bind the Disconnect button click event
        // me.Buttons.Disconnect.click(() => {
        //   me.udpListener.cleanup(() => {
        //     me.Callbacks.Disconnect();
        //   });
        // });

        // Bind the Clear button click event
        // me.Buttons.Clear.click(() => {
        //   me.Callbacks.ClearShots(() => {
        //     me.LocalStorage.Clear();
        //   });
        // });

        // Bind the Download button click event
        // me.Buttons.DownloadJSON.click((event) => {
        //   me.Callbacks.DownloadJSON();
        // });

        // Bind the Download button click event
        // me.Buttons.Download.click((event) => {
        //   me.Callbacks.Download();
        // });

        // Bind the TPMID changed event
        // me.Inputs.TPMID.on('keyup change', function () {
        //   me.requestedTPMID = Number($(this).val());
        //   // if we're connected, fire the callback
        //   if (me.udpListener && me.udpListener.connected == true) {
        //     me.Callbacks.TPMIDChanged(me.requestedTPMID);
        //   }
        // });

        // me.VizPanel.html('TODO: LM Viz Panel');

        // Load all shots
        // me.LocalStorage.LoadShots();
      };

      // callback function signatures
      me.Callbacks = {
        Connect(tpmID) {},
        Disconnect() {},
        DataReceived(jShot, wasAdded) {},
        DisplayShot(shotNum) {},
        ErrorReceived(info) {},
        TPMIDChanged(tpmid) {},
        LoadShots(shots) {},
        ClearShots(clearCB) {},
        DownloadJSON() {},
        Download() {},
      };

      // Local Datastore functions
      me.LocalStorage = {
        Sync() {
          localStorage.setItem("launchmonitorshots", me.shots);
        },
        AddShot(jShot) {
          console.log('addShot in Launch Monitor');
          if (!me.shots.some(s => s.GUID === jShot.GUID)) me.shots.push(jShot);
          localStorage.setItem("launchmonitorshots", me.shots);
          me.Callbacks.DisplayShot(me.shots.length - 1);
        },
        LoadShots() {
          me.shots = [];
          let data = localStorage.getItem('launchmonitorshots');
          if (data?.launchmonitorshots) {
            me.shots = data.launchmonitorshots;
            me.Callbacks.LoadShots(me.shots);
          }
        },
        Clear() {
          localStorage.setItem("launchmonitorshots", []);
          me.shots = [];
          // me.Callbacks.LoadShots(me.shots);
        },
      };

      // TODO: Use chrome native export functionality to save file
      me.Export = {};

      // converts a shot received from launch monitor to JSON
      me.processShot = function (shotString) {
        const shotDataArray = shotString.split(',');
        shotDataArray.forEach((v, k) => {
          shotDataArray[k] = v.trim();
        });

        const jShot = {
          GUID: me.Helpers.GUID(),
          DataPacketType: 'N/A',
          TimeCurrentReceivedShotMeasured: 0,
          TPMID: 0,
          Ball: {
            Speed: 0,
            LaunchAngle: 0,
            SideAngle: 0,
            BackSpin: 0,
            SideSpin: 0,
            RiflingSpin: 0,
            DataFit: [0, 0],
          },
          Distance: {
            Carry: 0,
            CarryDev: 0,
            Roll: 0,
            Total: 0,
            TotalDev: 0,
            ImpactSpin: 0,
            ImpactSpeed: 0,
            ImpactAngle: 0,
            PeakHeight: 0,
            PeakDistance: 0,
            FlightTime: 0,
          },
          Club: {
            Speed: 0,
            AttackAngle: 0,
            PathAngle: 0,
            LoftAngle: 0,
            FaceAngle: 0,
            DroopAngle: 0,
            LoftSpin: 0,
            FaceSpin: 0,
            DroopSpin: 0,
            HorizontalHitPos: 0,
            VerticalHitPos: 0,
            DataFit: [0, 0, 0],
          },
          ClubValid: 0,
          BallValid: 0,
          ClubID: 0,
          Note: '',
          Weather: {
            WindDir: null,
            WindSpeed: null,
            Temperature: null,
            Humidity: null,
            Pressure: null,
          },
          ConditionSettings: {
            ConditionID: null,
            Offset: 0,
          },
          GreenConditions: {
            GreenCondition: null,
            Compaction: 0,
            Moisture: 0,
            Stimp1: 0,
            Stimp2: 0,
          },
        };

        // determine type of launch monitor
        if (shotDataArray.length == 16) {
          // Light
          console.log('Light');
          jShot.DataPacketType = 'Player Test';
          jShot.TimeCurrentReceivedShotMeasured = shotDataArray[0];
          jShot.TPMID = Number(shotDataArray[1]);
          jShot.Ball.Speed = Number(shotDataArray[2]);
          jShot.Ball.LaunchAngle = Number(shotDataArray[3]);
          jShot.Ball.SideAngle = Number(shotDataArray[4]);
          jShot.Ball.BackSpin = Number(shotDataArray[5]);
          jShot.Ball.SideSpin = Number(shotDataArray[6]);
          jShot.Ball.RiflingSpin = Number(shotDataArray[7]);
          jShot.Club.Speed = Number(shotDataArray[8]);
          jShot.Club.AttackAngle = Number(shotDataArray[9]);
          jShot.Club.PathAngle = Number(shotDataArray[10]);
          jShot.Club.LoftAngle = Number(shotDataArray[11]);
          jShot.Club.FaceAngle = Number(shotDataArray[12]);
          jShot.Club.DroopAngle = Number(shotDataArray[13]);
          jShot.Club.HorizontalHitPos = Number(shotDataArray[14]);
          jShot.Club.VerticalHitPos = Number(shotDataArray[15]);
        } else if (shotDataArray.length == 24) {
          // Ball R&D
          console.log('Ball R&D');
          jShot.DataPacketType = 'Machine Test';
          jShot.TimeCurrentReceivedShotMeasured = shotDataArray[0];
          jShot.TPMID = Number(shotDataArray[1]);
          jShot.Ball.Speed = Number(shotDataArray[2]);
          jShot.Ball.LaunchAngle = Number(shotDataArray[3]);
          jShot.Ball.SideAngle = Number(shotDataArray[4]);
          jShot.Ball.BackSpin = Number(shotDataArray[5]);
          jShot.Ball.SideSpin = Number(shotDataArray[6]);
          jShot.Ball.RiflingSpin = Number(shotDataArray[7]);
          jShot.Ball.DataFit = [
            Number(shotDataArray[8]),
            Number(shotDataArray[9]),
          ];

          jShot.Club.Speed = Number(shotDataArray[10]);
          jShot.Club.AttackAngle = Number(shotDataArray[11]);
          jShot.Club.PathAngle = Number(shotDataArray[12]);
          jShot.Club.LoftAngle = Number(shotDataArray[13]);
          jShot.Club.FaceAngle = Number(shotDataArray[14]);
          jShot.Club.DroopAngle = Number(shotDataArray[15]);
          jShot.Club.HorizontalHitPos = Number(shotDataArray[16]);
          jShot.Club.VerticalHitPos = Number(shotDataArray[17]);
          jShot.Club.DataFit = [
            Number(shotDataArray[18]),
            Number(shotDataArray[19]),
            Number(shotDataArray[20]),
          ];

          jShot.ClubValid = shotDataArray[21];
          jShot.BallValid = shotDataArray[22];
          jShot.ClubID = shotDataArray[23];
        } else if (shotDataArray.length == 30) {
          // Club R&D
          console.log('Club R&D');
          jShot.DataPacketType = 'Club R&D';
          jShot.TimeCurrentReceivedShotMeasured = shotDataArray[0];
          jShot.TPMID = Number(shotDataArray[1]);
          jShot.Ball.Speed = Number(shotDataArray[2]);
          jShot.Ball.LaunchAngle = Number(shotDataArray[3]);
          jShot.Ball.SideAngle = Number(shotDataArray[4]);
          jShot.Ball.BackSpin = Number(shotDataArray[5]);
          jShot.Ball.SideSpin = Number(shotDataArray[6]);
          jShot.Ball.RiflingSpin = Number(shotDataArray[7]);
          jShot.Ball.DataFit = [
            Number(shotDataArray[11]),
            Number(shotDataArray[12]),
          ];

          jShot.Distance.Carry = Number(shotDataArray[8]);
          jShot.Distance.CarryDev = Number(shotDataArray[9]);
          jShot.Distance.Total = Number(shotDataArray[10]);

          jShot.Club.Speed = Number(shotDataArray[13]);
          jShot.Club.AttackAngle = Number(shotDataArray[14]);
          jShot.Club.PathAngle = Number(shotDataArray[15]);
          jShot.Club.LoftAngle = Number(shotDataArray[16]);
          jShot.Club.FaceAngle = Number(shotDataArray[17]);
          jShot.Club.DroopAngle = Number(shotDataArray[18]);
          jShot.Club.LoftSpin = Number(shotDataArray[19]);
          jShot.Club.FaceSpin = Number(shotDataArray[20]);
          jShot.Club.DroopSpin = Number(shotDataArray[21]);
          jShot.Club.HorizontalHitPos = Number(shotDataArray[22]);
          jShot.Club.VerticalHitPos = Number(shotDataArray[23]);
          jShot.Club.DataFit = [
            Number(shotDataArray[24]),
            Number(shotDataArray[25]),
            Number(shotDataArray[26]),
          ];

          jShot.ClubValid = shotDataArray[27];
          jShot.BallValid = shotDataArray[28];
          jShot.ClubID = shotDataArray[29];
        } else if (shotDataArray.length == 38) {
          // Club R&D Traj
          console.log('Club R&D Traj');
          jShot.DataPacketType = 'Club R&D Traj';
          jShot.TimeCurrentReceivedShotMeasured = shotDataArray[0];
          jShot.TPMID = Number(shotDataArray[1]);
          jShot.Ball.Speed = Number(shotDataArray[2]);
          jShot.Ball.LaunchAngle = Number(shotDataArray[3]);
          jShot.Ball.SideAngle = Number(shotDataArray[4]);
          jShot.Ball.BackSpin = Number(shotDataArray[5]);
          jShot.Ball.SideSpin = Number(shotDataArray[6]);
          jShot.Ball.RiflingSpin = Number(shotDataArray[7]);
          jShot.Ball.DataFit = [
            Number(shotDataArray[19]),
            Number(shotDataArray[20]),
          ];

          jShot.Distance.Carry = Number(shotDataArray[12]);
          jShot.Distance.CarryDev = Number(shotDataArray[13]);
          jShot.Distance.Roll = Number(shotDataArray[11]);
          jShot.Distance.Total = Number(shotDataArray[14]);
          jShot.Distance.TotalDev = Number(shotDataArray[15]);
          jShot.Distance.ImpactSpin = Number(shotDataArray[8]);
          jShot.Distance.ImpactSpeed = Number(shotDataArray[9]);
          jShot.Distance.ImpactAngle = Number(shotDataArray[10]);
          jShot.Distance.PeakHeight = Number(shotDataArray[16]);
          jShot.Distance.PeakDistance = Number(shotDataArray[17]);
          jShot.Distance.FlightTime = Number(shotDataArray[18]);

          jShot.Club.Speed = Number(shotDataArray[21]);
          jShot.Club.AttackAngle = Number(shotDataArray[22]);
          jShot.Club.PathAngle = Number(shotDataArray[23]);
          jShot.Club.LoftAngle = Number(shotDataArray[24]);
          jShot.Club.FaceAngle = Number(shotDataArray[25]);
          jShot.Club.DroopAngle = Number(shotDataArray[26]);
          jShot.Club.LoftSpin = Number(shotDataArray[27]);
          jShot.Club.FaceSpin = Number(shotDataArray[28]);
          jShot.Club.DroopSpin = Number(shotDataArray[29]);
          jShot.Club.HorizontalHitPos = Number(shotDataArray[30]);
          jShot.Club.VerticalHitPos = Number(shotDataArray[31]);
          jShot.Club.DataFit = [
            Number(shotDataArray[32]),
            Number(shotDataArray[33]),
            Number(shotDataArray[34]),
          ];

          jShot.ClubValid = shotDataArray[35];
          jShot.BallValid = shotDataArray[36];
          jShot.ClubID = shotDataArray[37];
        }
        return jShot;
      };

      // Method to add a shot
      me.AddShot = function (jShot) {
        me.shots = [ jShot, ...me.shots.filter(s => s.GUID !== jShot.GUID) ]; // push(jShot);
        me.LocalStorage.AddShot(jShot);
      };

      // Method to remove a shot by ID
      me.RemoveShot = function (shotID, cb) {
        console.log("REMOVING SHOT:", shotID)
        me.shots = me.shots.filter(s => s.GUID !== shotID);
        me.LocalStorage.Sync();
        // fire callback
        if (typeof cb === "function") {
          cb(me.shots);
        }
      };

      me.SaveConditionSettings = function (jShot) {
        console.log(`jShot: ${JSON.stringify(jShot)}`);
        console.log(`me.shots: ${JSON.stringify(me.shots)}`);
        me.LocalStorage.Sync();
      };

      // Method to retrieve a shot by ID
      me.GetShot = function (guid) {
        const result = me.shots.filter((obj) => obj.GUID == guid);
        return result[0];
      };

      // Method to retrieve all shots
      (me.AllShots = function (cb) {
        console.log("ME:", me)
        let data = localStorage.getItem('launchmonitorshots');
        let allShots = [];
        if (data?.launchmonitorshots) {
          allShots = data.launchmonitorshots;
        }
        allShots = me.shots; // Bypass localstorage for the moment 
        if (typeof cb === "function") {
          cb(allShots);
        }
      }),
      // Method to update a specified shot's Note
      (me.UpdateShotNote = function (guid, note) {
        me.shots.forEach((v, k) => {
          if (v.GUID == guid) {
            me.shots[k].Note = note;
            me.LocalStorage.Sync();
          }
        });
      });

      // Initialize the TPMID
      me.SetTPMID(me.requestedTPMID);
    };

    return model;
  })();
}(window));
