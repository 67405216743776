import { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomInput from './CustomInput.jsx';
import CustomButton from './CustomButton.jsx';
import { calculateAverage } from '../helpers/general';
import getConditions from '../data/conditionsArray';
import '../sensors/LaunchMonitor.jsx';
import Trackman from '../sensors/Trackman';
import { SerialComm } from '../comm/Serial';
import { WebSocketServer } from '../comm/WebSocketServer';

export default function Monitor() {
  let firstTime = 0;
  const UDP_PORT = 41172;

  const newConditionForm = {
    selectedIndex: 0,
    conditionId: '',
    greenCondition: '',
    offset: '',
    compaction1: '',
    compaction2: '',
    compaction3: '',
    moisture1: '',
    moisture2: '',
    moisture3: '',
    stimp1: '',
    stimp2: '',
    compactionAvg: '',
    moistureAvg: '',
  };

  const [sensorNav, setSensorNav] = useState('');
  const [updateConditionVisibility, setUpdateConditionVisibility] = useState(false);
  const [lmPortLeica, setLmPortLeica] = useState(UDP_PORT);
  const [lmPortUdp, setLmPortUdp] = useState(UDP_PORT);
  const [lmPortWs, setLmPortWs] = useState(UDP_PORT);
  const [tpmId, setTpmId] = useState(0);
  const [logMessages, setLogMessages] = useState([]);
  const [vizPanelVisibility, setVizPanelVisibility] = useState('');
  const [conditionForm, setConditionForm] = useState(newConditionForm);
  const [conditionsObj, setConditionsObj] = useState(null);
  const [wsConnected, setWsConnected] = useState(false);
  const [lmConnected, setLmConnected] = useState(false);
  const [leicaConnected, setLeicaConnected] = useState(false);
  const [vizPanelContent, setVizPanelContent] = useState('');
  const [websocketURL, setWebsocketURL] = useState('');
  const [websocketClients, setWebsocketClients] = useState(null);
  const [appHeaderClickable, setAppHeaderClickable] = useState(false);
  const [websocketDisconnectDialog, setWebsocketDisconnectDialog] = useState(false);
  const [newWSS, setNewWSS] = useState(null);
  const [shotRowConditions, setShotRowConditions] = useState([]);
  const [note, setNote] = useState({});

  const LMRef = useRef(new window.LaunchMonitor(0));

  const conditionsArray = getConditions();
  const halfWedgeOptions = conditionsArray[0];
  const pitchingWedgeOptions = conditionsArray[1];
  const fiveIronOptions = conditionsArray[2];
  const sevenIronOptions = conditionsArray[3];
  // console.log('CONDITIONS:', conditionsArray);

  const logMessage = (sMessage) => {
    const d = new Date();
    const n = d.toUTCString();
    setLogMessages((lm) => ([{
      n,
      message: sMessage,
    }, ...lm]));
  };

  const DownloadJSON = (jsonData, filename) => {
    console.log('DOWNLOADING JSON:');
    let newJsonData = jsonData;
    if (!newJsonData) {
      console.error('Console.save: No data');
      return;
    }

    if (typeof newJsonData === 'object') {
      newJsonData = JSON.stringify(newJsonData, undefined, 4); // .replace(/\s/g, '');
    }

    const blob = new Blob([newJsonData], { type: 'text/json' });
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
    a.dispatchEvent(e);
  };
  // const LM = new window.LaunchMonitor(0);

  function handleASwitch(e, jShot) {
    setTpmId(jShot.TPMID);
    LMRef.current.SetTPMID(jShot.TPMID);
    LMRef.current.AddShot(jShot);
    console.log('aSWITCH:', e.target);
  }

  const Serial = new SerialComm({
    // GetDevices(ports) {
    //   [ports].forEach((k, p) => {
    //     console.log(p.path);
    //     logMessage(p.path);
    //   });
    // },
    AfterConnected(path, conInfo) {
      console.log(conInfo);
      logMessage(
        `Connected to Serial Port: ${
          path
        } | Conn. ID: ${
          conInfo.connectionId}`,
      );
    },
    DataReceived(str) {
      // console.log(data);
      logMessage(str);
    },
  });

  // console.log('LAUNCH MONITOR:', LMRef.current);
  // console.log('SOCKET_UDP:', UdpListener);
  // console.log('SERIAL:', Serial);
  // console.log('UDP_CLIENT:', UdpClient);
  // console.log('WEBSOCKET:', WSS);

  useEffect(() => {
    if (firstTime > 0) return;
    firstTime += 1;

    logMessage('Application started...');
    // setVizPanelContent('TODO: LM Viz Panel');

    LMRef.current.init({
      Connect(tpmID) {
        setTpmId(tpmID);
        logMessage(`Connected to LM#${tpmID}`);
      },
      Disconnect() {
        setWsConnected(false);
        // LM.UI.GetButton('Connect').removeClass(
        //   'btn-success',
        // );
        logMessage('Disconnected: Websocket closed');
      },
      DataReceived(jShot, wasAdded) {
        console.log('new LM shot recieved');

        const aSwitch = <a key={jShot.TPMID} onClick={(e) => handleASwitch(e, jShot)}>{`[add shot to table and switch to LM#${
          jShot.TPMID
        }]`}</a>;

        const htm = <>
          <span>{`LM#${
            jShot.TPMID
          } recorded a shot with ball speed of ${
            jShot.Ball.Speed
          }. [${
            jShot.DataPacketType
          } mode]`}</span>{' - '}
          {wasAdded ? ' [shot added to table]' : (<><span> </span>{aSwitch}</>)}
        </>;

        //   .data('jshot', jShot)
        //   .click(function () {
        //     me.sensors.LaunchMonitor.AddShot(jShot);
        //     me.sensors.LaunchMonitor.SetTPMID(jShot.TPMID);
        //     $(this).parent().append('[shot added to table]');
        //     $(this).remove();
        //   });

        logMessage(htm);
      },
      ErrorReceived(info) {
        logMessage(`Error on socket with code ${info.resultCode}`);
      },
      DisplayShot(shots, shotNum) {
        const shot = shots[shotNum];

        // Add weather call here? Or in Launch Monitor?
        // Make async call to weather station and retrieve weather
        fetch({
          url: 'http://10.16.0.35/latestsampledata.xml',
          dataType: 'xml',
          async: true,
          crossDomain: true,
          success(xml) {
          // const $xml = $(xml);
          // const dir = Number($xml.find('meas[name="mtRawWindDir"]').text());
          // const speed = Number($xml.find('meas[name="mtWindSpeed"]').text());
          // const temp = Number($xml.find('meas[name="mtTemp1"]').text());
          // const hum = Number($xml.find('meas[name="mtRelHumidity"]').text());
          // const bp = Number($xml.find('meas[name="mtAdjBaromPress"]').text());
          // const objWeather = {
          //   WindDir: dir,
          //   WindSpeed: speed,
          //   Temperature: temp,
          //   Humidity: hum,
          //   Pressure: bp,
          // };
          // shot.Weather = objWeather;
          // console.log('weather retrieved');
          // console.log(shot);

          // LaunchMonitor.addShotToTable(shots, shotNum);
          // updateLayout();
          },
          error(data) {
          // successmessage = 'Error';
            console.log(`error message: ${JSON.stringify(data)}`);
          },
        });
      },
      TPMIDChanged(tpmid) {
        setTpmId(tpmid);
        logMessage(`Connected to LM#${tpmid}`);
      },
      LoadShots(shots) {
        LMRef.current.loadAllShots(shots);
      },
      ClearShots(clearCB) {
      // confirmation
      // bootbox.dialog({
      //   message:
      //     'Click CLEAR ALL SHOTS to remove all shots.<br /><br /><span class="warning">THIS CANNOT BE UNDONE!</span>',
      //   buttons: {
      //     cancel: {
      //       label: 'Cancel',
      //       className: 'btn-default',
      //       callback() {
      //         // do nothing
      //       },
      //     },
      //     delete: {
      //       label: 'CLEAR ALL SHOTS',
      //       className: 'btn-danger',
      //       callback() {
      //         clearCB();
      //         logMessage('shot table cleared');
      //       },
      //     },
      //   },
      // });
      },
      DownloadJSON() {
        LMRef.current.AllShots((allshots) => {
          console.log('ALL SHOTS:', allshots);
          // add shot number to each shot
          const newShots = allshots.map((s, k) => ({ ...s, ShotNum: k + 1 })) || LMRef.current.shots;
          DownloadJSON(newShots, 'launch-monitor.json');
        });
      },
      Download() {
      // $('#LaunchMonitorContainer').Table2Excel({
      //   name: 'LM-Data',
      // });
        logMessage('excel shot file exported');
      },
    });

    console.log('lm inited:', LMRef.current);
    LMRef.current.LocalStorage.LoadShots();
  }, []);

  const recalculateConditionValues = (_conditionForm) => {
    const avgCompaction = calculateAverage(
      parseFloat(_conditionForm.compaction1),
      parseFloat(_conditionForm.compaction2),
      parseFloat(_conditionForm.compaction3),
    ).toFixed(3);
    const avgMoisture = calculateAverage(
      parseFloat(_conditionForm.moisture1),
      parseFloat(_conditionForm.moisture2),
      parseFloat(_conditionForm.moisture3),
    ).toFixed(3);
    return {
      ..._conditionForm,
      compactionAvg: avgCompaction,
      moistureAvg: avgMoisture,
    };
  };

  const navClickHandler = (event, menuOption) => {
    event.preventDefault();
    event.stopPropagation();
    setSensorNav(menuOption);
  };

  const handleCloseCondition = () => {
    setUpdateConditionVisibility(false);
  };

  const handleClearCondition = () => {
    setConditionForm(newConditionForm);
  };

  const handleConditionChange = (e) => {
    const { value, name } = e.target;
    const updatedConditionForm = recalculateConditionValues({
      ...conditionForm,
      [name]: value,
    });
    setConditionForm(updatedConditionForm);
  };

  const handleConditionSelectChange = (value) => {
    setConditionForm({
      ...conditionForm,
      conditionId: value,
    });
    console.log('TARGET:', value);
  };

  const handleVizPanelVisibility = (vizPanel) => {
    if (vizPanel === vizPanelVisibility) {
      setVizPanelVisibility('');
    } else {
      setVizPanelVisibility(vizPanel);
    }
  };

  const handleConditionSave = () => {
    const newConditionsObj = {
      ConditionSettings: {
        ConditionID: conditionForm.conditionId,
        Offset: conditionForm.offset,
      },
      GreenConditions: {
        GreenCondition: conditionForm.greenCondition,
        Compaction: conditionForm.compactionAvg,
        Moisture: conditionForm.moistureAvg,
        Stimp1: conditionForm.greenCondition, // The original code for Chromeapp uses greenCondition fields instead of stimp fields
        Stimp2: conditionForm.greenCondition,
      },
    };
    console.log(JSON.stringify(conditionsObj));
    setConditionsObj(newConditionsObj);
    setUpdateConditionVisibility(false);
    logMessage(conditionForm.greenCondition);
  };

  const handleAppheaderButton = () => {
    // confirmation
    if (!appHeaderClickable) return;
    setWebsocketDisconnectDialog(true);
    // bootbox.dialog({
    //   message:
    //       'Click BOOT CLIENTS to disconnect all clients connected via Websockets.</span>',
    //   buttons: {
    //     cancel: {
    //       label: 'Cancel',
    //       className: 'btn-default',
    //       callback() {
    //         // do nothing
    //       },
    //     },
    //     delete: {
    //       label: 'BOOT CLIENTS',
    //       className: 'btn-danger',
    //       callback() {
    //         WSS.BootAllClients();
    //       },
    //     },
    //   },
    // });
  };

  const handleTrackmanConnect = () => true;

  const handleLeicaConnect = () => true;

  const handleLMConnect = async () => {
    if (wsConnected) {
      newWSS.Disconnect();
      setWsConnected(false);
      return;
    }
    // console.log('WWSS:', WSS);
    // WSS.Connect();
    const wss = new WebSocketServer(
      // ************************
      // Callbacks
      // ************************
      {
        ServerStarted(url) {
          setWebsocketURL(url);
          setWebsocketClients(0);
          logMessage('Websocket: Server Started...');
          setAppHeaderClickable(true);
        },
        ClientConnected(numClients) {
          setWebsocketClients(numClients);
          logMessage('Websocket: Client connected');
        },
        ClientDisconnected(numClients) {
          logMessage('Websocket: Client disconnected');
          setWebsocketClients(numClients);
        },
        MessageReceived(m) {
          console.log('MESSAGE:', m);
          logMessage('Websocket: Message received from client');
          const jShot = LMRef.current.processShot(m);
          console.log('JSHOT:', jShot);
          const addShotToArray = jShot.TPMID === LMRef.current.requestedTPMID;
          LMRef.current.Callbacks.DataReceived(jShot, addShotToArray);
          if (addShotToArray) {
            LMRef.current.AddShot(jShot);
          }
        },
        MessageSent(m, numClients) {
          setWebsocketClients(numClients);
          if (numClients > 0) {
            logMessage(
              `Websocket: Message sent to ${
                numClients
              } connected clients`,
            );
          }
        },
        port: lmPortWs,
        url: process.env.REACT_APP_HOST_URL,
      },
    );
    setNewWSS(wss);

    try {
      await wss.Connect();
      setWsConnected(true);
    } catch (e) {
      logMessage(e);
    }
    console.log('WSS SERVER:', wss);

    let jShot;
    // we are not connected, connect.
    // wss.cleanup_create(
    //   lmPortUdp,
    //   (rc) => {
    //     // data received
    //     console.log('rc:', rc);
    //     const dataView = new DataView(rc.data);
    //     const decoder = new TextDecoder('utf-8');
    //     const decodedString = decoder.decode(dataView);
    //     console.log(decodedString);
    //     jShot = LM.processShot(decodedString);

    // const addShotToArray = jShot.TPMID === LMRef.current.requestedTPMID;

    //     // data received callback (this callback is where weather is retrieved: in SensorMonitor.js)
    // LMRef.current.Callbacks.DataReceived(jShot, addShotToArray);

    //     if (jShot.TPMID === LM.requestedTPMID) {
    //       // add shot to local array and update storage
    //       LM.AddShot(jShot);
    //     }
    //     // safety valve. If we receive a shot we're connected. Display 'Disconnect' for the button
    //     LM.Buttons.Connect.html(LM.Buttons.Connect.data('altText'));
    //   },
    //   (info) => {
    //     // data error
    //     LM.Callbacks.ErrorReceived(info); // jShot
    //   },
    // );
    // LM.Buttons.Connect.html(LM.Buttons.Connect.data('altText'));

    // call the connect callback
    LMRef.current.Callbacks.Connect(LMRef.current.requestedTPMID);
    // }
  };

  const handleLMClear = () => {
    LMRef.current.LocalStorage.Clear();
    logMessage('All shots cleared');
  };

  const handleBootAllClients = () => {
    newWSS.BootAllClients();
    setWebsocketDisconnectDialog(false);
  };

  const handleLMTPMIDChange = (value) => {
    setTpmId(value);
    LMRef.current.SetTPMID(value);
    // if (UdpListener && UdpListener.connected) {
    if (wsConnected) {
      LMRef.current.Callbacks.TPMIDChanged(value);
    }
    // }
  };

  const handleLMShotNote = (shotGuid) => {
    if (note.id === shotGuid) {
      setNote({ id: '', text: '' });
    } else {
      console.log('opening comment pop-up');
      setNote({ id: shotGuid, text: LMRef.current.GetShot(shotGuid).Note });
    }
  };

  const handleLMShotDelete = (shotGuid, row) => {
    LMRef.current.RemoveShot(shotGuid, logMessage(`Shot #${row} deleted`));
  };

  const handleUpdateCondition = () => {
    setUpdateConditionVisibility(true);
  };

  const launchMonitorTextToColumnsSingleShot = (shotID, shotNote) => {
    console.log('SHOT NOTE:', shotNote);
    const splitted = shotNote.split('\n', 4);
    const offset = LMRef.current.GetShot(shotID).ConditionSettings.Offset;

    // assign the contents of the array to their appropriate columns:

    setShotRowConditions((_shotRowConditions) => {
      const newShot = _shotRowConditions.filter((row) => row.shotID !== shotID);
      newShot.push({
        shotID,
        col0: splitted[0],
        col1: offset,
        col2: splitted[0] - offset,
        col3: splitted[1],
      });
      console.log('NEW SHOT:', newShot);
      return newShot;
    });
  };

  const launchMonitorTextToColumnsAllShots = () => {
    const { shots } = LMRef.current;

    // console.log("shots: ", shots);
    setShotRowConditions([]);

    // loop through all shots and assign all their comments to the appropriate columns:

    for (let i = 0; i < shots.length; i += 1) {
      const shot = shots[i];
      // console.log("shot from within lmttcas:", shot);
      launchMonitorTextToColumnsSingleShot(shot.GUID, shot.Note);
    }
  };

  const trackmanTextToColumnsAllShots = () => true;

  const updateLMShotNote = (shotGuid) => {
    LMRef.current.UpdateShotNote(shotGuid, note.text);
    launchMonitorTextToColumnsSingleShot(shotGuid, note.text);
    setNote({ id: '', text: '' });
  };

  // console.log('SENSOR NAV:', sensorNav);
  // console.log('CONDITIONS OBJ:', conditionsObj);
  return (
    <>
      <body>
        <div id="wrap">
          <div id="window-bar">
            <h1>Titleist Sensor Monitor v. {process.env.REACT_APP_VERSION}</h1>
            <div>
              <button id="win-minimize" className="btn btn-xs btn-default">
            &#95;
              </button>
              <button
                id="win-restore"
                className="btn btn-xs btn-default glyphicon glyphicon-resize-small hideMobile"
              ></button>
              <button
                id="win-maximize"
                className="btn btn-xs btn-default glyphicon glyphicon glyphicon-fullscreen hideMobile"
              ></button>
              <button
                id="win-close"
                className="btn btn-xs btn-default btn-danger glyphicon glyphicon glyphicon glyphicon-remove"
              ></button>
            </div>
          </div>
          <div id="AppHeader" className={sensorNav !== '' ? 'active' : ''}>
            <ul id="SensorNav">
              <li className="hideMobile">Select &raquo;</li>
              <li className={sensorNav === 'LaunchMonitorContainer' ? 'active' : ''}>
                <a href="#" data-tab="LaunchMonitorContainer"
                  onClick={(e) => navClickHandler(e, 'LaunchMonitorContainer')}
                ><span className="hideMobile">Launch Monitor</span
                  ><span className="mobileOnly">LM</span></a
                >
              </li>
              <li className={sensorNav === 'TrackmanContainer' ? 'active' : ''}>
                <a href="#" data-tab="TrackmanContainer"
                  onClick={(e) => navClickHandler(e, 'TrackmanContainer')}
                ><span className="hideMobile">Trackman</span
                  ><span className="mobileOnly">TM</span></a
                >
              </li>
              {/* <li className={`${sensorNav}` === 'LeicaContainer' ? ' active' : ''}>
                <a href="#" data-tab="LeicaContainer"
                  onClick={(e) => navClickHandler(e, 'LeicaContainer')}
                ><span className="hideMobile">Leica</span
                  ><span className="mobileOnly">Leica</span></a
                >
              </li>
              <li className={`${sensorNav}` === 'JuggsSettingsContainer' ? ' active' : ''}>
                <a href="#" data-tab="JuggsSettingsContainer"
                  onClick={(e) => navClickHandler(e, 'JuggsSettingsContainer')}
                ><span className="hideMobile">Juggs Settings</span
                  ><span className="mobileOnly">Juggs</span></a
                >
              </li> */}
            </ul>
            <div>
              <span className="hideMobile">WS URL:</span>
              <span id="websocket-status">{websocketURL}</span>
          #Clients:
              <span id="websocket-clients">{websocketClients}</span>
              <button onClick={handleAppheaderButton}
                className="btn btn-xs btn-warning glyphicon glyphicon-remove-sign"
              ></button>
            </div>
          </div>
          {/* <!-- Launch Monitor Tab --> */}
          <div id="LaunchMonitorContainer" className="sensor-container" style={{ display: (sensorNav === 'LaunchMonitorContainer') ? 'block' : 'none' }}>
            <div className="sensor-header">
              <div className="padded" style={{ position: 'relative' }}>
                <span id="lm-port-udp">WS Port:&nbsp;
                  <CustomInput typeAttr={'tel'} className="form-control" style={{ width: '80px' }} value={lmPortWs} setValue={setLmPortWs} />
                </span>
                <span id="lm-tpmid">LM#:&nbsp;
                  <CustomInput typeAttr={'tel'} className="form-control" style={{ width: '80px' }} value={tpmId} setValue={handleLMTPMIDChange} />
                </span>
                <span id="lm-buttons">
                  <CustomButton text={wsConnected ? 'Disconnect' : 'Connect'} altText="Disconnect" className={`btn btn-primary btn-sm${wsConnected ? ' btn-success' : ''}`} style={{ marginRight: '10px' }} handleClick={handleLMConnect} />
                </span>
                <span className="nowrap"
                >Shot Count: <span className="shotCount"></span
                  >{LMRef.current.shots.length}</span>
                <div hidden id="conditionSettings"></div>
                <div id="lm-buttons-right" className="buttons-right hideMobile">
                  {/* <button className="btn btn-default btn-sm updateCondition" onClick={handleUpdateCondition} style={{ marginRight: '10px' }}>Update Condition</button> */}
                  <CustomButton text="Clear Launch Monitor Shots" className="btn btn-danger btn-sm" style={{ marginRight: '10px' }} handleClick={handleLMClear} />
                  {/* <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={launchMonitorTextToColumnsAllShots}>Map all Text to  Columns</button> */}
                  <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={LMRef.current.Callbacks.DownloadJSON}><i className="glyphicon glyphicon-download text-muted"></i> Download (JSON)</button>
                  <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={LMRef.current.Callbacks.DownloadExcel}><i className="glyphicon glyphicon-download text-muted"></i> Download (Excel)</button>
                </div>

              </div>
            </div>
            {/* <!-- wide screen data table view --> */}
            <table className="shot-table-header hideMobile">
              <tbody>
                <tr className="noExl">
                  <th colSpan="2" className="cellLast noExl">
                Buttons
                  </th>
                  {/* <th colSpan="1" className="cellLast noExl">
                    <span>Condition ID</span>
                  </th> */}
                  <th colSpan="3" className="cellLast noExl">
                    <span>Text to Columns</span>
                  </th>

                  <th
                    colSpan="8"

                    className="cellLast cellHidden noExl"
                  >
                    <span>Club</span>
                  </th>
                  <th colSpan="4" className="cellLast noExl">
                    <span>Ball</span>
                  </th>
                  {/* <!-- <th
                colspan="3"

                class="cellLast noExl hideMobile"
              >
                <span>Distance</span>
              </th> --> */}
                  <th
                    colSpan="1"

                    className="noExl hideMobile hideTablet"
                  >
                    <span>Valid</span>
                  </th>
                  <th
                    colSpan="3"

                    className="noExl cellHidden hideMobile hideTablet"
                  >
                    <span>Wind</span>
                  </th>
                </tr>
                <tr>
                  <th className="w32 fixedWidth align-center noExl">
                    <span><span className="glyphicon glyphicon-trash"></span></span>
                  </th>
                  <th className="w32 fixedWidth align-center noExl">
                    <span><span className="glyphicon glyphicon-comment"></span></span>
                  </th>
                  {/* <th className="w32 fixedWidth align-center noExl">
                    <span><span className="glyphicon glyphicon-cloud"></span></span>
                  </th> */}

                  <th className="w32 fixedWidth"><span>#</span></th>
                  <th className="cellHidden"><span>Time</span></th>
                  <th className="w50 fixedWidth cellLast hideMobile">
                    <span>LMID</span>
                  </th>
                  {/* <th className="w50 fixedWidth cellLast hideMobile">
                    <span>Condition ID</span>
                  </th>
                  <th className="w50 fixedWidth cellHidden hideMobile">
                    <span>Green Condition</span>
                  </th>
                  <th>
                    <span><span>Map to Columns </span>Total</span>
                  </th>
                  <th>
                    <span><span>Map to Columns </span>Offset</span>
                  </th>
                  <th>
                    <span><span>Map to Columns </span>Rollout</span>
                  </th> */}
                  <th className="cellLast" style={{ width: '30%' }}>
                    <span><span>Map to Columns </span>Note</span>
                  </th>

                  <th className="cellHidden noExl">
                    <span><span>Club </span>Speed</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Attack&deg;</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Path&deg;</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Loft&deg;</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Face&deg;</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Droop&deg;</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Loft Spin</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Face Spin</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Droop Spin</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>H Impact</span>
                  </th>
                  <th className="cellHidden noExl cellLast">
                    <span><span>Club </span>V Impact</span>
                  </th>
                  <th className="cellHidden noExl">
                    <span><span>Club </span>Data Fit</span>
                  </th>

                  <th>
                    <span><span>Ball </span>Speed</span>
                  </th>
                  <th>
                    <span><span>Ball </span>Launch&deg;</span>
                  </th>
                  <th className="cellHidden">
                    <span><span>Ball </span>Side&deg;</span>
                  </th>
                  <th>
                    <span><span>Ball </span>Back Spin</span>
                  </th>
                  <th className="cellLast">
                    <span><span>Ball </span>Side Spin</span>
                  </th>
                  <th className="cellHidden">
                    <span><span>Ball </span>Rifling Spin</span>
                  </th>
                  <th className="cellHidden">
                    <span><span>Ball </span>Data Fit</span>
                  </th>

                  <th className="cellHidden hideMobile noExl"><span>Carry</span></th>
                  <th className="cellHidden hideMobile noExl">
                    <span>Offset</span>
                  </th>
                  <th className="cellHidden noExl"><span>Roll</span></th>
                  <th className="cellHidden cellLast hideMobile noExl">
                    <span>Total</span>
                  </th>
                  <th className="cellHidden noExl"><span>Total Offset</span></th>
                  <th className="cellHidden noExl"><span>Impact Spin</span></th>
                  <th className="cellHidden noExl"><span>Impact Speed</span></th>
                  <th className="cellHidden noExl"><span>Impact Angle</span></th>
                  <th className="cellHidden noExl"><span>Peak Height</span></th>
                  <th className="cellHidden noExl"><span>Dist at Peak</span></th>
                  <th className="cellHidden noExl"><span>Flight Time</span></th>

                  <th className="cellHidden hideMobile hideTablet noExl">
                    <span>Club<span> Valid</span></span>
                  </th>
                  <th className="hideMobile hideTablet">
                    <span>Ball<span> Valid</span></span>
                  </th>
                  <th className="cellHidden hideTablet noExl"><span>Club ID</span></th>
                  <th className="cellHidden hideTablet"><span>Note</span></th>
                  <th className="cellHidden hideTablet"><span>Wind Speed</span></th>
                  <th className="cellHidden hideTablet"><span>Wind Dir</span></th>
                  <th className="cellHidden hideTablet"><span>Temperature</span></th>
                  <th className="cellHidden hideTablet"><span>Humidity</span></th>
                  <th className="cellHidden hideTablet"><span>Pressure</span></th>
                  <th className="cellHidden hideTablet"><span>Compaction</span></th>
                  <th className="cellHidden hideTablet"><span>Moisture</span></th>
                  <th className="cellHidden hideTablet"><span>Stimp1</span></th>
                  <th className="cellHidden hideTablet"><span>Stimp2</span></th>

                  <th
                    width="16"
                    rowSpan="2"
                    style={{ width: '16px', background: 'none' }}
                    className="noExl"
                  ></th>
                  {/* <!-- padder to account for scrollbar --> */}
                </tr>
                {
                  // Mapping shots
                  LMRef.current.shots.map((shot, shotNum) => (
                    <tr key={shot.GUID}>
                      <td className="w32 fixedWidth align-center noExl">
                        <span>
                          <a className="LMShotRemove glyphicon glyphicon-trash" role="button" onClick={() => handleLMShotDelete(shot.GUID, LMRef.current.shots.length - shotNum)}></a>
                        </span>
                      </td>
                      <td className="w32 fixedWidth align-center noExl">
                        <span>
                          <a title={shot.Note} onClick={() => handleLMShotNote(shot.GUID)} className="LMShotNoteEdit glyphicon glyphicon-comment" role="button"></a>
                          {
                            note.id === shot.GUID
                          && <div className="note-box">
                            <p><textarea onChange={(e) => setNote({ ...note, text: e.target.value })}>{note.text}</textarea></p>
                            <button onClick={() => setNote({ id: '', text: '' })} className="btnCancel btn btn-default btn-small">Cancel</button>
                            <button onClick={() => updateLMShotNote(shot.GUID)} className="btnUpdate btn btn-primary btn-small">Update</button>
                          </div>
                          }
                        </span>
                      </td>
                      {/* <td className="w32 fixedWidth align-center noExl">
                        <span>
                          <a className="LMGetWeather glyphicon glyphicon-cloud" role="button"></a>
                        </span>
                      </td> */}
                      <td className="w32 fixedWidth">
                        <span>
                          {LMRef.current.shots.length - shotNum}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.TimeCurrentReceivedShotMeasured || ' '}
                        </span>
                      </td>
                      <td className="w50 fixedWidth cellLast hideMobile">
                        <span>
                          {shot.TPMID}
                        </span>
                      </td>
                      {/* <td className="w50 fixedWidth cellLast hideMobile">
                        <span>
                          {shot.ConditionSettings.ConditionID}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.GreenConditions.GreenCondition || ' '}
                        </span>
                      </td>
                      */ }
                      <td id="LMCol-3" className="cellLast">
                        <span>{shotRowConditions.find((row) => row.shotID === shot.GUID)?.col0}</span>
                      </td>
                      {/*
                      <td id="LMCol-1">
                        <span>{shotRowConditions.find((row) => row.shotID === shot.GUID)?.col1}</span>
                      </td>
                      <td id="LMCol-2">
                        <span>{shotRowConditions.find((row) => row.shotID === shot.GUID)?.col2}</span>
                      </td>
                      <td id="LMCol-3" className="cellLast">
                        <span>{shotRowConditions.find((row) => row.shotID === shot.GUID)?.col3}</span>
                      </td> */}
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.Speed.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.AttackAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.PathAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.LoftAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.FaceAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.DroopAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.LoftSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.FaceSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.DroopSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.HorizontalHitPos.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.VerticalHitPos.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Club.DataFit}
                        </span>
                      </td>
                      <td>
                        <span>
                          {shot.Ball.Speed.toFixed(1)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {shot.Ball.LaunchAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden"z>
                        <span>
                          {shot.Ball.SideAngle.toFixed(1)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {shot.Ball.BackSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellLast">
                        <span>
                          {shot.Ball.SideSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Ball.RiflingSpin.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Ball.DataFit}
                        </span>
                      </td>
                      <td className="cellHidden hideMobile noExl">
                        <span>
                          {shot.Distance.Carry.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden hideMobile noExl">
                        <span>
                          {shot.Distance.CarryDev.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.Roll.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden cellLast hideMobile noExl">
                        <span>
                          {shot.Distance.Total.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.ImpactSpeed.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.ImpactAngle.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.PeakHeight.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.PeakDistance.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden noExl">
                        <span>
                          {shot.Distance.FlightTime.toFixed(1)}
                        </span>
                      </td>
                      <td className="cellHidden hideMobile hideTablet noExl">
                        <span>
                          {shot.clubValid}
                        </span>
                      </td>
                      <td className="hideMobile hideTablet">
                        <span>
                          {shot.BallValid}
                        </span>
                      </td>
                      <td className="cellHidden hideTablet noExl">
                        <span>
                          {shot.ClubID}
                        </span>
                      </td>
                      <td className="cellHidden hideTablet">
                        <span className="cellShotNote">
                          {shot.Note}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Weather.WindSpeed}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Weather.WindDir}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Weather.Temperature}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Weather.Humidity}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.Weather.Pressure}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.GreenConditions.Compaction}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.GreenConditions.Moisture}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.GreenConditions.Stimp1}
                        </span>
                      </td>
                      <td className="cellHidden">
                        <span>
                          {shot.GreenConditions.Stimp2}
                        </span>
                      </td>
                    </tr>

                  ))
                }
              </tbody>
            </table>
            <div className={`shot-viz${(vizPanelVisibility === 'lm') ? ' visible' : ''}`}>
              {
                vizPanelContent || <>
                  <button
                    className="btn btn-xs btn-default glyphicon glyphicon-option-vertical"
                    onClick={() => handleVizPanelVisibility('lm')}
                  ></button>
                  <div id="lm-shot-viz"></div>
                </>
              }
            </div>
            <div id="conditionFormDiv" style={{ visibility: updateConditionVisibility ? 'visible' : 'hidden' }}>
              <div id="conditionFormHeader"><h4>Condition Settings</h4></div>
              <form id="conditionForm" target="_blank">
                <label htmlFor="greenCondition">Select Green Condition:</label>

                <div className="radio-select">
                  <label htmlFor="radio1">Hard</label>

                  <input
                    type="radio"
                    id="radio1"
                    name="greenCondition"
                    value="hard"
                    onChange={handleConditionChange}
                  />
                  <label htmlFor="radio2">Med</label>

                  <input
                    type="radio"
                    id="radio2"
                    name="greenCondition"
                    value="med"
                    onChange={handleConditionChange}
                  />
                  <label htmlFor="radio3">Soft</label>

                  <input
                    type="radio"
                    id="radio3"
                    name="greenCondition"
                    value="soft"
                    onChange={handleConditionChange}
                  />
                </div>

                <label htmlFor="condition">Select Condition from Dropdown:</label>

                <select name="halfWedge" className="conditionSelect" id="halfWedge"
                  onChange={(e) => handleConditionSelectChange(e.target.value) }
                  value={ halfWedgeOptions.find((hw) => hw.value === conditionForm.conditionId) ? conditionForm.conditionId : '' }
                >
                  <option value="" disabled selected>-- Half wedge --</option>
                  {
                    halfWedgeOptions.map((halfWedge) => (<option key={halfWedge.value} value={halfWedge.value}>{halfWedge.label}</option>))
                  }
                </select>
                <select name="pWedge" className="conditionSelect" id="pWedge"
                  onChange={(e) => handleConditionSelectChange(e.target.value) }
                  value={ pitchingWedgeOptions.find((pw) => pw.value === conditionForm.conditionId) ? conditionForm.conditionId : '' }
                >
                  <option value="" disabled selected>-- P. Wedge--</option>
                  {
                    pitchingWedgeOptions.map((pWedge) => (<option key={pWedge.value} value={pWedge.value}>{pWedge.label}</option>))
                  }
                </select>
                <select name="5iron" className="conditionSelect" id="5iron"
                  onChange={(e) => handleConditionSelectChange(e.target.value) }
                  value={ fiveIronOptions.find((fi) => fi.value === conditionForm.conditionId) ? conditionForm.conditionId : '' }
                >
                  <option value="" disabled selected>-- 5 Iron --</option>
                  {
                    fiveIronOptions.map((fiveIron) => (<option key={fiveIron.value} value={fiveIron.value}>{fiveIron.label}</option>))
                  }
                </select>
                <select name="7iron" className="conditionSelect" id="7iron"
                  onChange={(e) => handleConditionSelectChange(e.target.value) }
                  value={ sevenIronOptions.find((si) => si.value === conditionForm.conditionId) ? conditionForm.conditionId : '' }
                >
                  <option value="" disabled selected>-- 7 Iron --</option>
                  {
                    sevenIronOptions.map((sevenIron) => (<option key={sevenIron.value} value={sevenIron.value}>{sevenIron.label}</option>))
                  }
                </select>

                <div>
                  <label htmlFor="condition_id">Condition ID:</label>
                  <input
                    required
                    type="text"
                    id="condition_id"
                    name="conditionId"
                    value={conditionForm.conditionId}
                    onChange={handleConditionChange}
                  />
                </div>
                <div>
                  <label htmlFor="offset">Offset:</label>
                  <input type="text" id="offset" name="offset" required
                    value={conditionForm.offset}
                    onChange={handleConditionChange}
                  />
                </div>
                <div id="compactionDiv">
                  <label htmlFor="compaction"> Compaction: </label>
                  <input
                    type="text"
                    id="compaction1"
                    name="compaction1"
                    className="compactionInput"
                    required
                    value={conditionForm.compaction1}
                    onChange={handleConditionChange}
                  />
                  <input
                    type="text"
                    id="compaction2"
                    name="compaction2"
                    className="compactionInput"
                    required
                    value={conditionForm.compaction2}
                    onChange={handleConditionChange}
                  />
                  <input
                    type="text"
                    id="compaction3"
                    name="compaction3"
                    className="compactionInput"
                    required
                    value={conditionForm.compaction3}
                    onChange={handleConditionChange}
                  />
                  <div id="conditionAvg">
                    <p id="compactionAvg">Avg: {conditionForm.compactionAvg}</p>
                  </div>
                </div>
                <div id="moistureDiv">
                  <label htmlFor="moisture">Moisture:</label>
                  <input
                    type="text"
                    id="moisture1"
                    name="moisture1"
                    className="moistureInput"
                    required
                    value={conditionForm.moisture1}
                    onChange={handleConditionChange}
                  />
                  <input
                    type="text"
                    id="moisture2"
                    name="moisture2"
                    className="moistureInput"
                    required
                    value={conditionForm.moisture2}
                    onChange={handleConditionChange}
                  />
                  <input
                    type="text"
                    id="moisture3"
                    name="moisture3"
                    className="moistureInput"
                    required
                    value={conditionForm.moisture3}
                    onChange={handleConditionChange}
                  />
                  <div id="conditionAvg">
                    <p id="moistureAvg">Avg: {conditionForm.moistureAvg}</p>
                  </div>
                </div>
                <div id="stimpDiv">
                  <label htmlFor="stimp1">Stimp</label>
                  <input
                    type="text"
                    id="stimp1"
                    name="stimp1"
                    className="stimpInput"
                    required
                    value={conditionForm.stimp1}
                    onChange={handleConditionChange}
                  />
                  <input
                    type="text"
                    id="stimp2"
                    name="stimp2"
                    className="stimpInput"
                    required
                    value={conditionForm.stimp2}
                    onChange={handleConditionChange}
                  />
                </div>
                <div id="conditionSettingsBtns">
                  <button type="button" id="saveConditionBtn" onClick={handleConditionSave}>
                      Save changes
                  </button>
                  <button id="closeConditionBtn" onClick={handleCloseCondition}>Close</button>
                  <button id="clearConditionBtn" onClick={handleClearCondition}>Clear</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- mobile data view --> */}
          <div className="shot-data-view mobileOnly">
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Ball</div>
                <div className="panel-body">
                  <aside>
                    <h3>Spin</h3>
                    <p><span id="lm-dp-ball-backspin">{LMRef.current.shots[0]?.Ball.BackSpin.toFixed(0) || '---'}</span> rpm</p>
                  </aside>
                  <aside>
                    <h3>Speed</h3>
                    <p><span id="lm-dp-ball-speed">{LMRef.current.shots[0]?.Ball.Speed.toFixed(1) || '---'}</span> mph</p>
                  </aside>
                  <aside>
                    <h3>Launch</h3>
                    <p><span id="lm-dp-ball-launch">{LMRef.current.shots[0]?.Ball.LaunchAngle.toFixed(1) || '---'}</span>&deg;</p>
                  </aside>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Club</div>
                <div className="panel-body">
                  <aside>
                    <h3>Speed</h3>
                    <p><span id="lm-dp-club-speed">{LMRef.current.shots[0]?.Club.Speed.toFixed(1) || '---'}</span> mph</p>
                  </aside>
                  <aside>
                    <h3>Attack</h3>
                    <p><span id="lm-dp-club-attack">{LMRef.current.shots[0]?.Club.AttackAngle.toFixed(1) || '---'}</span>&deg;</p>
                  </aside>
                  <aside>
                    <h3>Path</h3>
                    <p><span id="lm-dp-club-path">{LMRef.current.shots[0]?.Club.PathAngle.toFixed(1) || '---'}</span>&deg;</p>
                  </aside>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Distance</div>
                <div className="panel-body">
                  <aside>
                    <h3>Carry</h3>
                    <p><span id="lm-dp-dist-carry">{LMRef.current.shots[0]?.Distance.Carry.toFixed(1) || '---'}</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Offset</h3>
                    <p><span id="lm-dp-dist-offset">{LMRef.current.shots[0]?.Distance.CarryDev.toFixed(1) || '---'}</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Total</h3>
                    <p><span id="lm-dp-dist-total">{LMRef.current.shots[0]?.Distance.Total.toFixed(1) || '---'}</span> yds</p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Trackman Tab --> */}
        <div id="TrackmanContainer" className="sensor-container" style={{ display: (sensorNav === 'TrackmanContainer') ? 'block' : 'none' }}>
          {/* <!-- wide screen data table view --> */}
          <div className="sensor-header">
            <div className="padded" style={{ position: 'relative' }}>
              <span id="tm-ipaddr">Connection Type:
                <CustomInput typeAttr={'tel'} className="form-control" style={{ width: '150px' }} value={lmPortUdp} setValue={setLmPortUdp} />
              </span>
              <span id="tm-buttons">
                <CustomButton text="Connect" className="btn btn-primary btn-sm" style={{ marginRight: '10px' }} handleClick={handleTrackmanConnect} />
              </span>
              <span>Shot Count: <span className="shotCount"></span></span>
              <div id="tm-buttons-right" className="buttons-right hideMobile">
                <CustomButton text="Clear Trackman Shots" className="btn btn-danger btn-sm" style={{ marginRight: '10px' }} />
                {/* <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={trackmanTextToColumnsAllShots}>Map all Text to Columns</button> */}
                <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={Trackman.downloadJSON}><i className="glyphicon glyphicon-download text-muted"></i> Download (JSON)</button>
                <button className="btn btn-default btn-sm" style={{ marginRight: '10px' }} onClick={Trackman.downloadExcel}><i className="glyphicon glyphicon-download text-muted"></i> Download (Excel)</button>
              </div>
            </div>
          </div>
          <table className="shot-table-header hideMobile">
            <tbody>
              <tr className="noExl">
                <th
                  colSpan="3"

                  className="cellLast noExl"
                ></th>
                <th colSpan="4" className="cellLast noExl">
                  <span>Text to Columns</span>
                </th>
                <th colSpan="6" className="cellLast noExl">
                  <span>Ball</span>
                </th>
                <th colSpan="3" className="cellLast noExl">
                  <span>Club</span>
                </th>
                <th colSpan="6" className="cellLast noExl">
                  <span>Flight</span>
                </th>
              </tr>
              <tr>
                <th className="w32 align-center noExl">
                  <span><span className="glyphicon glyphicon-trash"></span></span>
                </th>
                <th className="w32 align-center noExl">
                  <span><span className="glyphicon glyphicon-comment"></span></span>
                </th>
                <th className="cellLast w32"><span>#</span></th>
                <th className="cellHidden noExl"><span>Time</span></th>

                <th><span>Ball ID</span></th>
                <th>
                  <span><span>Leica </span>Carry (ft)</span>
                </th>
                <th>
                  <span><span>Leica </span>Total (ft)</span>
                </th>
                <th className="align-center cellLast" style={{ width: '200px' }}>
                  <span>Comment</span>
                </th>

                <th>
                  <span><span>Ball </span>Speed</span>
                </th>
                <th>
                  <span><span>Ball </span>Smash</span>
                </th>
                <th>
                  <span><span>Ball </span>Launch&deg;</span>
                </th>
                <th>
                  <span><span>Ball </span>Launch Dir.</span>
                </th>
                <th>
                  <span><span>Ball </span>Spin Rate</span>
                </th>
                <th className="cellLast">
                  <span><span>Ball </span>Spin Axis</span>
                </th>

                <th>
                  <span><span>Club </span>Speed</span>
                </th>
                <th>
                  <span><span>Club </span>Attack&deg;</span>
                </th>
                <th className="cellLast">
                  <span><span>Club </span>Path&deg;</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Loft&deg;</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Face&deg;</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Loft Spin</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Face to Path</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Swing Plane</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Swing Dir</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Swing Radius</span>
                </th>
                <th className="cellHidden">
                  <span><span>Club </span>Face Tilt</span>
                </th>

                <th><span>Pk. Height</span></th>
                <th><span>Carry</span></th>
                <th><span>C. Offset</span></th>
                <th><span>Total</span></th>
                <th className="cellHidden"><span>T. Offset</span></th>
                <th className="cellLast"><span>Land&deg;</span></th>
                <th className="cellHidden"><span>Land Speed</span></th>
                <th className="cellHidden"><span>Hang</span></th>
                <th className="cellHidden"><span>Last Data</span></th>
                <th className="cellHidden"><span>Note</span></th>
                <th className="cellHidden"><span>JSON</span></th>

                <th
                  rowSpan="2"
                  style={{ width: '15px', background: 'none' }}
                  className="noExl"
                ></th>
                {/* <!-- padder to account for scrollbar --> */}
              </tr>
            </tbody>
          </table>
          <div className="shot-table-container hideMobile">
            <table className="shot-table">
              <tbody>
                {

                }
              </tbody>
            </table>
            <div className={`shot-viz${(vizPanelVisibility === 'tm') ? ' visible' : ''}`}>
              <button
                className="btn btn-xs btn-default glyphicon glyphicon-option-vertical"
                onClick={() => handleVizPanelVisibility('tm')}
              ></button>
              <div id="tm-shot-viz"></div>
            </div>
          </div>
          {/* <!-- mobile data view --> */}
          <div className="shot-data-view mobileOnly">
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Ball</div>
                <div className="panel-body">
                  <aside>
                    <h3>Spin</h3>
                    <p><span id="tm-dp-ball-backspin">---</span> rpm</p>
                  </aside>
                  <aside>
                    <h3>Speed</h3>
                    <p><span id="tm-dp-ball-speed">---</span> mph</p>
                  </aside>
                  <aside>
                    <h3>Launch</h3>
                    <p><span id="tm-dp-ball-launch">---</span>&deg;</p>
                  </aside>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Club</div>
                <div className="panel-body">
                  <aside>
                    <h3>Speed</h3>
                    <p><span id="tm-dp-club-speed">---</span> mph</p>
                  </aside>
                  <aside>
                    <h3>Attack</h3>
                    <p><span id="tm-dp-club-attack">---</span>&deg;</p>
                  </aside>
                  <aside>
                    <h3>Path</h3>
                    <p><span id="tm-dp-club-path">---</span>&deg;</p>
                  </aside>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="panel panel-info">
                <div className="panel-heading">Flight</div>
                <div className="panel-body">
                  <aside>
                    <h3>Peak</h3>
                    <p><span id="tm-dp-dist-peak">---</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Carry</h3>
                    <p><span id="tm-dp-dist-carry">---</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Offset</h3>
                    <p><span id="tm-dp-dist-offset">---</span> yds</p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Leica Tab --> */}
        <div id="LeicaContainer" className="sensor-container" style={{ display: (sensorNav === 'LeicaContainer') ? 'block' : 'none' }}>
          <div className="sensor-header">
            <div className="padded" style={{ position: 'relative' }}>
              <span id="lm-port-leica">COM Port:&nbsp;
                <CustomInput typeAttr={'tel'} className="form-control" style={{ width: '80px' }} value={lmPortLeica} setValue={setLmPortLeica} />
              </span>
              <span id="lm-buttons">
                <CustomButton text="Connect" altText="Disconnect" className={`btn btn-primary btn-sm${leicaConnected ? ' btn-success' : ''}`} style={{ marginRight: '10px' }} handleClick={handleLeicaConnect} />
              </span>
              <span className="nowrap"
              >Shot Count: <span className="shotCount"></span
                ></span>
              <div
                id="leica-buttons-right"
                className="buttons-right hideMobile"
              ></div>
            </div>
          </div>
          {/* <!-- wide screen data table view --> */}
          <table className="shot-table-header hideMobile">
            <tbody>
              <tr>
                <th className="align-center noExl w32">
                  <span><span className="glyphicon glyphicon-trash"></span></span>
                </th>
                <th className="align-center noExl w32">
                  <span><span className="glyphicon glyphicon-comment"></span></span>
                </th>
                <th className="w32"><span>#</span></th>
                <th className="cellHidden noExl"><span>Time</span></th>
                <th><span>Carry</span></th>
                <th><span>Total</span></th>
                <th className="cellHidden"><span>Note</span></th>

                <th
                  rowSpan="2"
                  style={{ width: '15px', background: 'none' }}
                  className="noExl"
                ></th>
                {/* <!-- padder to account for scrollbar --> */}
              </tr>
            </tbody>
          </table>
          <div className="shot-table-container hideMobile">
            <table className="shot-table">
              <tbody>
                {

                }
              </tbody>
            </table>
            <div className={`shot-viz${(vizPanelVisibility === 'leica') ? ' visible' : ''}`}>
              <button
                className="btn btn-xs btn-default glyphicon glyphicon-option-vertical"
                onClick={() => handleVizPanelVisibility('leica')}
              ></button>
              <div id="lm-shot-viz"></div>
            </div>
          </div>
          {/* <!-- mobile data view --> */}
          <div className="shot-data-view mobileOnly">
            <div className="col-sm-12">
              <div className="panel panel-info">
                <div className="panel-heading">Distance</div>
                <div className="panel-body">
                  <aside>
                    <h3>Carry</h3>
                    <p><span id="leica-dp-carry">---</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Roll</h3>
                    <p><span id="leica-dp-roll">---</span> yds</p>
                  </aside>
                  <aside>
                    <h3>Total</h3>
                    <p><span id="leica-dp-total">---</span> yds</p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Juggs Config Tab --> */}
        <div id="JuggsSettingsContainer" className="sensor-container hide-viz" style={{ display: (sensorNav === 'JuggsSettingsContainer') ? 'block' : 'none' }}>
          <div className="sensor-header">
            <div className="padded" style={{ position: 'relative' }}>
            Enter target conditions to obtain setting for BOLM and Juggs
            </div>
          </div>
          <div className="shot-data-view">
            <div id="target-inputs" className="col-sm-12">
              <div className="panel panel-info">
                <div className="panel-heading">Target Barrel Conditions</div>
                <div className="panel-body">
                  <div className="col-sm-4">
                    <aside>
                      <h3>Back Spin</h3>
                      <p><span id="juggs-ball-backspin">
                        <input type="tel" className="form-control input-xl" style={{ width: '120px' }} />
                      </span> rpm</p>
                    </aside>
                  </div>
                  <div className="col-sm-4">
                    <aside>
                      <h3>Ball Speed</h3>
                      <p><span id="juggs-ball-speed">
                        <input type="tel" className="form-control input-xl" style={{ width: '120px' }} />
                      </span> mph</p>
                    </aside>
                  </div>
                  <div className="col-sm-4">
                    <aside>
                      <h3>Ball Angle</h3>
                      <p><span id="juggs-ball-angle">
                        <input type="tel" className="form-control input-xl" style={{ width: '120px' }} />
                      </span> deg</p>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="juggs-settings-bolm" className="col-sm-4">
                <div className="panel panel-info">
                  <div className="panel-heading">BOLM Settings</div>
                  <div className="panel-body">
                    <aside>
                      <h3>Init Delay</h3>
                      <p><span id="juggs-bolm-initdelay">---</span></p>
                    </aside>
                    <aside>
                      <h3>Delay</h3>
                      <p><span id="juggs-bolm-delay">---</span></p>
                    </aside>
                  </div>
                </div>
              </div>
              <div id="juggs-settings-wheel" className="col-sm-4">
                <div className="panel panel-info">
                  <div className="panel-heading">Wheel Settings</div>
                  <div className="panel-body">
                    <aside>
                      <h3>Top Wheel</h3>
                      <p>
                        <label>Top Pot:</label>
                        <span id="juggs-wheel-top-pot">---</span>
                      </p>
                      <p>
                        <label>Speed:</label>
                        <span id="juggs-wheel-top-speed">---</span>
                      </p>
                    </aside>
                    <aside>
                      <h3>Bottom Wheel</h3>
                      <p>
                        <label>Bot Pot:</label>
                        <span id="juggs-wheel-bottom-pot">---</span>
                      </p>
                      <p>
                        <label>Speed:</label>
                        <span id="juggs-wheel-bottom-speed">---</span>
                      </p>
                    </aside>
                  </div>
                </div>
              </div>
              <div id="juggs-settings-angle" className="col-sm-4">
                <div className="panel panel-info">
                  <div className="panel-heading">Juggs Angle</div>
                  <div className="panel-body">
                    <aside>
                      <h3>Angle</h3>
                      <p><span id="juggs-angle">---</span></p>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="console"><ul>{
          logMessages.map((msg, i) => (
            <li key={i}>
              {`${msg.n} :: `}
              <span>{(msg.message)}</span>
            </li>
          ))
        }
        </ul></div>

        <Modal show={websocketDisconnectDialog} onHide={() => setWebsocketDisconnectDialog(false)}>
          <div
            className={`modal ${websocketDisconnectDialog ? 'show' : ''}`}
            style={{ display: websocketDisconnectDialog ? 'block' : 'none', position: 'relative' }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Boot clients</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Click BOOT CLIENTS to disconnect all clients connected via Websockets.</p>
            </Modal.Body>

            <Modal.Footer>
              <Button className="btn-default" variant="secondary" onClick={() => setWebsocketDisconnectDialog(false)}>Cancel</Button>
              <Button className="btn-danger" variant="primary" onClick={handleBootAllClients}>BOOT CLIENTS</Button>
            </Modal.Footer>
          </div>
        </Modal>
      </body>
    </>
  );
}
