const halfwedge = [
  { value: 'hw1', label: 'hw-1', club: 'hw' },
  { value: 'hw2', label: 'hw-2', club: 'hw' },
  { value: 'hw3', label: 'hw-3', club: 'hw' },
  { value: 'hw4', label: 'hw-4', club: 'hw' },
  { value: 'hw5', label: 'hw-5', club: 'hw' },
  { value: 'hw6', label: 'hw-6', club: 'hw' },
  { value: 'hw7', label: 'hw-7', club: 'hw' },
  { value: 'hw8', label: 'hw-8', club: 'hw' },
  { value: 'hw9', label: 'hw-9', club: 'hw' },
  { value: 'hw10', label: 'hw-10', club: 'hw' },
  { value: 'hw11', label: 'hw-11', club: 'hw' },
  { value: 'hw12', label: 'hw-12', club: 'hw' },
  { value: 'hw13', label: 'hw-13', club: 'hw' },
  { value: 'hw14', label: 'hw-14', club: 'hw' },
  { value: 'hw15', label: 'hw-15', club: 'hw' },
  { value: 'hw16', label: 'hw-16', club: 'hw' },
];

const pitchingWedge = [
  { value: 'pw1', label: 'pw-1', club: 'pw' },
  { value: 'pw2', label: 'pw-2', club: 'pw' },
  { value: 'pw3', label: 'pw-3', club: 'pw' },
  { value: 'pw4', label: 'pw-4', club: 'pw' },
  { value: 'pw5', label: 'pw-5', club: 'pw' },
  { value: 'pw6', label: 'pw-6', club: 'pw' },
  { value: 'pw7', label: 'pw-7', club: 'pw' },
  { value: 'pw8', label: 'pw-8', club: 'pw' },
  { value: 'pw9', label: 'pw-9', club: 'pw' },
  { value: 'pw10', label: 'pw-10', club: 'pw' },
  { value: 'pw11', label: 'pw-11', club: 'pw' },
  { value: 'pw12', label: 'pw-12', club: 'pw' },
  { value: 'pw13', label: 'pw-13', club: 'pw' },
  { value: 'pw14', label: 'pw-14', club: 'pw' },
  { value: 'pw15', label: 'pw-15', club: 'pw' },
  { value: 'pw16', label: 'pw-16', club: 'pw' },
];

const sevenIron = [
  { value: '7i1', label: '7i-1', club: '7i' },
  { value: '7i2', label: '7i-2', club: '7i' },
  { value: '7i3', label: '7i-3', club: '7i' },
  { value: '7i4', label: '7i-4', club: '7i' },
  { value: '7i5', label: '7i-5', club: '7i' },
  { value: '7i6', label: '7i-6', club: '7i' },
  { value: '7i7', label: '7i-7', club: '7i' },
  { value: '7i8', label: '7i-8', club: '7i' },
  { value: '7i9', label: '7i-9', club: '7i' },
  { value: '7i10', label: '7i-10', club: '7i' },
  { value: '7i11', label: '7i-11', club: '7i' },
  { value: '7i12', label: '7i-12', club: '7i' },
  { value: '7i13', label: '7i-13', club: '7i' },
  { value: '7i14', label: '7i-14', club: '7i' },
  { value: '7i15', label: '7i-15', club: '7i' },
  { value: '7i16', label: '7i-16', club: '7i' },
];

const fiveIron = [
  { value: '5i1', label: '5i-1', club: '5i' },
  { value: '5i2', label: '5i-2', club: '5i' },
  { value: '5i3', label: '5i-3', club: '5i' },
  { value: '5i4', label: '5i-4', club: '5i' },
  { value: '5i5', label: '5i-5', club: '5i' },
  { value: '5i6', label: '5i-6', club: '5i' },
  { value: '5i7', label: '5i-7', club: '5i' },
  { value: '5i8', label: '5i-8', club: '5i' },
  { value: '5i9', label: '5i-9', club: '5i' },
  { value: '5i10', label: '5i-10', club: '5i' },
  { value: '5i11', label: '5i-11', club: '5i' },
  { value: '5i12', label: '5i-12', club: '5i' },
  { value: '5i13', label: '5i-13', club: '5i' },
  { value: '5i14', label: '5i-14', club: '5i' },
  { value: '5i15', label: '5i-15', club: '5i' },
  { value: '5i16', label: '5i-16', club: '5i' },
];

const conditionsArray = [halfwedge, pitchingWedge, fiveIron, sevenIron];

export default function getConditions() {
  return conditionsArray;
}
