/* eslint-disable */
/*
WebSocket implementation
*/
export function WebSocketServer(callbacks, apiEndpoints) {
    const me = this;
    me.wsPort = callbacks.port;
    me.url = callbacks.url;
    me.isServer = true;
    me.server = null;
    me.wsServer = null;

    // A list of connected websockets.
    me.connectedSockets = [];

    me.Callbacks = {
	  ServerStarted(url) { },
      ClientConnected(numClients) { },
      ClientDisconnected(numClients) { },
      MessageReceived(m) { },
      MessageSent(m, numClients) { },
    };

    me.APIEndpoints = [];

    // assign any specified callbacks
    if (callbacks) {
      if (callbacks.ServerStarted) { me.Callbacks.ServerStarted = callbacks.ServerStarted; }
      if (callbacks.ClientConnected) { me.Callbacks.ClientConnected = callbacks.ClientConnected; }
      if (callbacks.ClientDisconnected) { me.Callbacks.ClientDisconnected = callbacks.ClientDisconnected; }
      if (callbacks.MessageReceived) { me.Callbacks.MessageReceived = callbacks.MessageReceived; }
      if (callbacks.MessageSent) { me.Callbacks.MessageSent = callbacks.MessageSent; }
    }

    if (apiEndpoints) {
      me.APIEndpoints = apiEndpoints;
    }

    console.log("PLAIN WS:", WebSocket)

    me.Connect = () => {
      if (WebSocket) {
        console.log("ws url:", 'ws://' + me.url + ':' + me.wsPort)
        me.socket = new WebSocket('ws://' + me.url + ':' + me.wsPort);
        me.socket.addEventListener("error", (error) => {
          console.log("WS ERROR:", error)
        })
        me.socket.addEventListener('open', (event) => {
          me.Callbacks.ServerStarted(event.srcElement.url);
          console.log('WebSocket connection is open.', event.srcElement.url);
        });
        me.socket.addEventListener('message', (event) => {
          me.Callbacks.MessageReceived(event.data);
          console.log('Received message from the server:', event.data);
        });
      }
    }

    me.Disconnect = () => {
      me.socket.close();
      me.Callbacks.ClientDisconnected(1);
      console.log("Disconnected:", me.socket)
    }

      // get the ipaddress
      // chrome.system.network.getNetworkInterfaces((interfaces) => {
      /*
                    var ipaddr = 'N/A';
                    //console.log(interfaces);
                    //TODO: get the right address...sometimes the first one is an IPv6 address
                    if(interfaces && interfaces.length > 0) {
                        ipaddr = 'ws://' + interfaces[0].address + ':' + me.wsPort;
                    }
                    me.Callbacks.ServerStarted(ipaddr);

                    //TODO: broadcast the websocket IPAddress over UDP so that clients can find the hub
                    //Use port 42100
                    */
      // });

      // HTTP Server - Use this to provide a RESTFul API
    //   me.server.addEventListener('request', (req) => {
    //     let { url } = req.headers;
    //     const isAPIRequest = url.indexOf('api/') > -1;

    //     if (isAPIRequest) {
    //       let ErrorEp;
    //       $.each(me.APIEndpoints, (k, v) => {
    //         if (v.url == 'error') {
    //           ErrorEp = v;
    //         }
    //       });

    //       // check to see if endpoint exists in APIEndpoints
    //       let ep = null;
    //       $.each(me.APIEndpoints, (k, v) => {
    //         if (v.url == url) {
    //           ep = v;
    //         }
    //       });

    //       // if endpoint is defined then fire it
    //       let dataToReturn = '';
    //       if (ep) {
    //         if (ep.secure) {
    //           // TODO: implement header based security using guid (like trackman)
    //           // If proper guid is present in header then
    //           dataToReturn = JSON.stringify(ErrorEp.resp(req, 'Secure endpoint. Access Denied.'));
    //           req.serveJson(dataToReturn, ep.cb(req));
    //           return true;
    //         }
    //         // get the data
    //         ep.data((d) => {
    //           // console.log(JSON.stringify(d));
    //           dataToReturn = JSON.stringify(d);
    //           req.serveJson(dataToReturn, ep.cb(req));
    //           return true;
    //         });
    //       }
    //       // otherwise endpoint does not exist
    //       else {
    //         dataToReturn = JSON.stringify(ErrorEp.resp(req, 'Endpoint does not exist'));
    //         req.serveJson(dataToReturn, ep.cb(req));
    //         return true;
    //       }
    //       return true;
    //       // if we got here then something went wrong!
    //     }
    //     // not an api request
    //     if (url == '/') {
    //       url = '/home.html';
    //     }
    //     req.serveUrl(url);
    //     return true;
    //   });

    //   // wire up the websocket event handlers
    //   me.wsServer.addEventListener('request', (req) => {
    //     console.log(req);
    //     const socket = req.accept();
    //     me.connectedSockets.push(socket);

    //     me.Callbacks.ClientConnected(me.connectedSockets.length);

    //     // Handle messages received from clients
    //     socket.addEventListener('message', (m) => {
    //       me.Callbacks.MessageReceived(m);
    //     });

    //     // When a socket is closed, remove it from the list of connected sockets.
    //     socket.addEventListener('close', () => {
    //       me.Callbacks.ClientDisconnected(me.connectedSockets.length);
    //       for (let i = 0; i < me.connectedSockets.length; i++) {
    //         if (me.connectedSockets[i] == socket) {
    //           me.connectedSockets[i].close();
    //           me.connectedSockets.splice(i, 1);
    //           break;
    //         }
    //       }
    //     });
    //     return true;
    //   });
    // }

    // // Send a message to all connected clients
    // me.SendMessage = function (data) {
    //   for (let i = 0; i < me.connectedSockets.length; i++) {
    //     me.connectedSockets[i].send(data);
    //   }
    //   me.Callbacks.MessageSent(data, me.connectedSockets.length);
    // };

    // Disconnect all clients
    me.BootAllClients = function (cb) {
      console.log("BOOTING:")
      const numClients = me.connectedSockets.length;
      for (let i = 0; i < me.connectedSockets.length; i++) {
        me.connectedSockets[i].close();
      }
      me.connectedSockets = [];
      if (cb) {
        cb(numClients);
      }
    };
  };
