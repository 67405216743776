/* eslint-disable */ 
/*
Bluetooth implementation
*/
export function SerialComm(callbacks) {
        var me = function (callbacks) {

            var me = this;
            me._connectionid = -1;
            me._stringreceived = '';
            me.Callbacks = {
	    		// GetDevices: function(ports) { },
                AfterConnected: function(path, conInfo) { } ,
                DataReceived: function(data) { }          
	    	};
            
            //assign any specified callbacks
            if(callbacks) {
                // if(callbacks.GetDevices) { me.Callbacks.GetDevices = callbacks.GetDevices; }
                if(callbacks.AfterConnected) { me.Callbacks.AfterConnected = callbacks.AfterConnected; }
                if(callbacks.DataReceived) { me.Callbacks.DataReceived = callbacks.DataReceived; }
            };
            
            // me.GetDevices = function() {
            //     navigator.serial.requestPort().then(function(devices) {
            //         console.log("Devices:", devices)
            //         if(me.Callbacks.GetDevices) {
            //             me.Callbacks.GetDevices(devices);
            //         }
            //     });
            // };
            
            me.Connect = async function(sPath, opts, readCallback) {
                const device = await navigator.bluetooth.requestDevice({acceptAllDevices: true});
                console.log("DEVICE:", device)
                const server = await device.gatt.connect();
                console.log("CONNECTED:", server)
                // chrome.serial.connect(sPath, opts, function(conInfo) {
                //     me._connectionid = conInfo.connectionId;
                    
                //     if(me.Callbacks.DataReceived) {
                //         chrome.serial.onReceive.addListener(function(info) {
                //             if (info.connectionId == me._connectionid && info.data) {
                //                 var str = me.convertArrayBufferToString(info.data);
                //                 if (str.charAt(str.length-1) === '\n') {
                //                     me._stringreceived += str.substring(0, str.length-1);
                //                     me.Callbacks.DataReceived(me._stringreceived);
                //                     me._stringreceived = '';
                //                 } else {
                //                     me._stringreceived += str;
                //                 }
                //             }
                //         });
                //     }
                    
                    if(me.Callbacks.AfterConnected) {
                        me.Callbacks.AfterConnected(sPath, conInfo);
                    }
                // });
            }
            
            me.convertArrayBufferToString  = function(buf) {
                var bufView = new Uint8Array(buf);
                var encodedString = String.fromCharCode.apply(null, bufView);
                return decodeURIComponent(encodedString);
            }
        };
        return me;
    }
