import { useState } from 'react';
import PropTypes from 'prop-types';

export default function Input({
  className, typeAttr, style, value, setValue,
}) {
  const handleChange = (_value) => {
    setValue(_value);
  };

  return (
    <input
      type={typeAttr || 'text'}
      className={className}
      style={{ ...style, display: 'inline', marginRight: '12px' }}
      value={value}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
}

Input.propTypes = {
  className: PropTypes.string,
  typeAttr: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.number,
  setValue: PropTypes.func,
};
