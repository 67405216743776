import { useState } from 'react';
import PropTypes from 'prop-types';

export default function Button({
  className, style, text, altText, handleClick,
}) {
  return (
    <button
      className={className}
      alt={altText}
      style={{ ...style, display: 'inline', marginRight: '12px' }}
      onClick={(e) => handleClick()}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  altText: PropTypes.string,
  handleClick: PropTypes.func,
};
