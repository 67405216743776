import './css/App.css';
import './css/bootstrap.min.css';
import './css/glyphicons.css';
import './css/index.css';
import Monitor from './components/Monitor.jsx';

function App() {
  return (
    <div className="App">
      <Monitor />
    </div>
  );
}

export default App;
